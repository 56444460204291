@font-face {
  font-family: 'BMS Humanity';
  src: local('BMS Humanity'), url(/static/media/BMSHumanity-Regular.3a1940fa.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'BMS Humanity Bold';
  src: local('BMS Humanity Bold'), url(/static/media/BMSHumanity-Bold.e4f5a6b6.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/

body, body * {
  margin: 0;
  font-family: 'BMS Humanity' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  color: #595454;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div {
  box-sizing: border-box;
}

.divHeader {
  border-top: 10px solid #D9D9D9;
  background-color: #F6F6F6;
  padding: 20px 20px;
  text-align: center;
}

.divBody {
  height: calc(100vh - 97px);
  /*PONER*/
  /*background-image: url('./Images/Fondo.png');*/
  padding-top: 15px;
  /*QUITAR*/
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  overflow-y: auto;
}

.divBody>.pruebaDiv {
  padding: 10px 30px 25px;
  min-height: calc(100vh - 173px);
}

.divFooter {
  background-color: #D9D9D9;
  padding: 16px;
  color: #595454;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  position: relative;
}

.divFooter .txtFooter {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #595454;
  /* margin-bottom: 5px; */
  max-width: 695px;
}

.divFooter .enlacesFooter {
  max-width: 695px;
}

.divFooter a {
  text-decoration: none;
  color: #595454;
}

.divFooter .codBMS {
  color: #595454;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.tituloPagina {
  font-weight: 700;
  font-size: 30px;
  color: #595454;
  letter-spacing: 0.0025em;
}

.subtituloPagina {
  font-weight: 400;
  font-size: 26px;
  color: #595454;
  margin-top: 10px;
  letter-spacing: 0.0025em;
}

.tituloMenu {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #595454;
  margin-top: 20px;
}

#menuIconos {
  margin-top: 15px;
}

.botonGrado {
  border-radius: 15px;
  padding: 11px 5px;
  width: 90px;
  font-size: 14px;
  line-height: 15.4px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  display: grid;
  align-content: center;
}

.menuGrados {
  margin-top: 22px;
}

#contDatos {
  max-width: 920px;
  width: 100%;
  margin: 50px auto
}

.tituloAcordeon {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.tituloAcordeon sup {
  font-size: 12px;
}

.MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 20px 0px;
}

.textoAcordeon {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #A69F9F;
}

.textoAcordeon li {
  margin-bottom: 10px;
}

.bullet_Gastrointestinales li::marker {
  color: #D58910;
}

.bullet_Renales li::marker {
  color: #27AE60;
}

.bullet_Pulmonares li::marker {
  color: #E93B81;
}

.bullet_Hepaticas li::marker {
  color: #83469C;
}

.bullet_Endocrinopatia li::marker {
  color: #2980B9;
}

.bullet_Cutanea li::marker {
  color: #FF6363;
}

.bullet_Otras li::marker {
  color: #25AECC;
}

.colorGastro {
  color: #794e09;
}

.colorEndo {
  color: #1f608b;
}

#textoInfo {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #A69F9F;
}

.contSintoma {
  padding: 0px 15px;
}

.tituloSintoma {
  margin-top: 26px;
  font-size: 18px;
  color: #595454;
}

.grado {
  margin: 15px 0px 20px;
  border-radius: 10px;
  background-color: #FFF;
}

.nameGrado {
  /* display: inline-block;
  color: #FFF;
  writing-mode: sideways-lr;
  text-align: center;
  padding: 10px 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  display: inline-block;
  color: #FFF;
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  text-align: center;
  padding: 10px 3px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-bottom: -1px;
  margin-top: -1px;
  margin-left: -1px;
}

.textoGrado {
  padding: 20px 15px;
  font-size: 16px;
  line-height: 21px;
  color: #A69F9F;
  border-radius: 10px;
}

.textoGrado ul {
  margin: 0px;
}

.textoGrado li {
  line-height: 21px;
}

.textoGrado li:not(.textoGrado li:last-child) {
  margin-bottom: 15px;
}

.infoSintoma {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A69F9F;
  background-color: #fff;
  text-align: justify;
}

.seguimiento {
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* ------------------------ Inicio --------------------- */
.title_inicio {
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-top: 100px;
  color: #595454;
  margin-bottom: 15px;
}

.subtitle_inicio {
  font-size: 26px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.0025em;
  text-align: center;
  color: #595454;
  margin-bottom: 55px;
}

.body_inicio {
  min-height: calc(100vh - 77px);
  /*background-image: url('./Images/Fondo.png');*/
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  padding: 0px 30px 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.button_inicio {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.footer_inicio {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.0015em;
  text-align: justified;
  color: #A69F9F;

}

.alertInicio .MuiDialog-container .MuiPaper-root {
  border-radius: 2px;
}

.tituloPopupInicio {
  font-weight: 700;
  font-size: 28px;
  line-height: 31px;
  color: #595454;
  text-align: center;
}

.textoPopupInicio {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: justify;
  color: #595454;
  margin-bottom: 23px;
}

.btnAceptar {
  padding: 13px 20px;
  width: 215px;
  background: #BE2BBB;
  border-radius: 3px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  display: inline-block;
  cursor: pointer;
  /* margin-right: 45px; */
}

.btnCancelar {
  padding: 13px 20px;
  width: 215px;
  background: #EEE7E7;
  border-radius: 3px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #595454;
  display: inline-block;
  cursor: pointer;
}

.bodyPopup {
  color: #595454;
  font-size: 18px;
}

@media (max-width: 1170px) {

  .body_inicio {
    min-height: calc(100vh - 104px);
  }

  .divFooter .codBMS {
    color: #595454;
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 10px;
  }

  .divBody {
    height: calc(100vh - 97px);
  }
}

@media (max-width: 899px) {
  .celdaInsDer {
    padding: 0px 50px 10px !important;
  }

  .celdaInsIzq {
    padding: 10px 50px 0px !important;
  }
}

@media (max-width: 767px) {
  .tituloPagina {
    font-size: 28px;
  }

  .subtituloPagina {
    font-size: 24px;
  }

  .MuiPaper-root.MuiAccordion-root::before {
    background-color: transparent;
  }

  .contSintoma {
    padding: 0px;
  }

  .tituloSintoma {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }

  .tituloSintoma sup {
    font-size: 16px;
    font-weight: 400;
  }

  .divBody {
    height: calc(100vh - 87px);
  }

  .celdaInsDer {
    padding: 0px 30px 10px !important;
  }

  .celdaInsIzq {
    padding: 10px 30px 0px !important;
  }

  .textoPopupInicio {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .divBody > .pruebaDiv {
    padding: 10px 30px;
  }
  .tituloPagina {
    font-size: 23px;
  }

  .divBody {
    height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .title_inicio {
    font-size: 26px;
    line-height: 31px;
    margin-top: 70px;
  }

  .subtitle_inicio {
    font-size: 24px;
    margin-bottom: 31px;
  }

  .infoSintoma {
    word-break: break-word;
  }

  .divFooter {
    font-size: 12px;
  }

  .divFooter .txtFooter {
    font-size: 11px;
  }

  .celdaInsDer {
    padding: 0px 10px 10px !important;
    font-size: 15px;
  }

  .celdaInsIzq {
    padding: 10px 10px 0px !important;
  }

  .textoPopupInicio {
    text-align: center;
  }
}
.divBodyRegistro {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    padding: 25px 30px;
    border-top: 10px solid #D9D9D9;
}

.tituloRegistro {
    font-weight: 700;
    font-size: 38px;
    line-height: 129%;
    color: #595454;
    margin-bottom: 15px;
}

.subtituloRegistro {
    font-weight: 400;
    font-size: 26px;
    line-height: 129%;
    color: #595454;
}

.formRegistro {
    max-width: 1240px;
    width: 100%;
    margin: 39px auto
}

.tituloForm {
    font-weight: 400;
    font-size: 22px;
    line-height: 129%;
    color: #BE2BBB;
}

.infoForm {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A1A8B0;
    margin-top: 10px;
}

.textoCheckbox {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #3B4453;
}

.btnRegistro {
    width: 263px;
    background: #BE2BBB;
    border-radius: 32px;
    padding: 16px 10px;
    color: #FFF;
    text-align: center;
    margin: 0px auto;
    cursor: pointer;
    font-size: 18px;
}

.btnRegistroBlanco {
    width: 263px;
    background: #FFF;
    border-radius: 32px;
    padding: 15px 10px;
    color: #BE2BBB;
    text-align: center;
    margin: 0px auto;
    cursor: pointer;
    font-size: 18px;
    border: 2px solid #BE2BBB;
}

.btnInstalar {
    width: 130px;
    background: #BE2BBB;
    border-radius: 10px;
    padding: 9px 10px 9px 10px;
    color: #FFF;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
}

.btnInstalarBlanco {
    /* width: 201px; */
    background: #EEE7E7;
    border-radius: 15px;
    padding: 10px 19px;
    color: #595454;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}

.textoFooter {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A69F9F;
    margin-top: 80px;
}

.tituloPopup {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #BE2BBB;
}

.textoPopup {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #A1A8B0;
    margin-top: 10px;
}

.alertRegistro .MuiDialog-container .MuiPaper-root {
    border-radius: 24px;
}

@media (max-width: 575px) {
    .MuiFormControl-root.inputBuscador {
        width: calc(100% - 152px);
    }

    .cabeceraAndroid {
        padding: 16px 8px 16px 30px !important;
    }

    .textoCabeceraAndroid {
        font-size: 16px !important;
    }

    .btnInstalar {
        width: 113px;
        padding: 5px 36px 5px 36px;
        font-size: 12px;
        line-height: 21px;
    }

    .btnInstalarBlanco {
        padding: 9px 10px;
        font-size: 12px;
        line-height: 21px;
    }
}
