.divBodyRegistro {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    padding: 25px 30px;
    border-top: 10px solid #D9D9D9;
}

.tituloRegistro {
    font-weight: 700;
    font-size: 38px;
    line-height: 129%;
    color: #595454;
    margin-bottom: 15px;
}

.subtituloRegistro {
    font-weight: 400;
    font-size: 26px;
    line-height: 129%;
    color: #595454;
}

.formRegistro {
    max-width: 1240px;
    width: 100%;
    margin: 39px auto
}

.tituloForm {
    font-weight: 400;
    font-size: 22px;
    line-height: 129%;
    color: #BE2BBB;
}

.infoForm {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A1A8B0;
    margin-top: 10px;
}

.textoCheckbox {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #3B4453;
}

.btnRegistro {
    width: 263px;
    background: #BE2BBB;
    border-radius: 32px;
    padding: 16px 10px;
    color: #FFF;
    text-align: center;
    margin: 0px auto;
    cursor: pointer;
    font-size: 18px;
}

.btnRegistroBlanco {
    width: 263px;
    background: #FFF;
    border-radius: 32px;
    padding: 15px 10px;
    color: #BE2BBB;
    text-align: center;
    margin: 0px auto;
    cursor: pointer;
    font-size: 18px;
    border: 2px solid #BE2BBB;
}

.btnInstalar {
    width: 130px;
    background: #BE2BBB;
    border-radius: 10px;
    padding: 9px 10px 9px 10px;
    color: #FFF;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
}

.btnInstalarBlanco {
    /* width: 201px; */
    background: #EEE7E7;
    border-radius: 15px;
    padding: 10px 19px;
    color: #595454;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}

.textoFooter {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A69F9F;
    margin-top: 80px;
}

.tituloPopup {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #BE2BBB;
}

.textoPopup {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #A1A8B0;
    margin-top: 10px;
}

.alertRegistro .MuiDialog-container .MuiPaper-root {
    border-radius: 24px;
}

@media (max-width: 575px) {
    .MuiFormControl-root.inputBuscador {
        width: calc(100% - 152px);
    }

    .cabeceraAndroid {
        padding: 16px 8px 16px 30px !important;
    }

    .textoCabeceraAndroid {
        font-size: 16px !important;
    }

    .btnInstalar {
        width: 113px;
        padding: 5px 36px 5px 36px;
        font-size: 12px;
        line-height: 21px;
    }

    .btnInstalarBlanco {
        padding: 9px 10px;
        font-size: 12px;
        line-height: 21px;
    }
}